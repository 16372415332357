import { render, staticRenderFns } from "./PlaygroundPage.vue?vue&type=template&id=05ac4377&scoped=true"
import script from "./PlaygroundPage.vue?vue&type=script&lang=js"
export * from "./PlaygroundPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05ac4377",
  null
  
)

export default component.exports